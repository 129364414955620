/* eslint-disable dot-notation */
import {
  titleSizePx,
  titleSizeToClass,
} from 'app/_components/ContentfulPage/contentfulPage.utils'
import { IImage } from '@utils/contentful/contentfulImage'
import { useDevice } from '@utils/responsiveness/useDevice'
import {
  THorizontalPosition,
  TOverlay,
  TSize,
  TVerticalPosition,
} from '@utils/types'
import classnames from 'classnames'
import { CardImage } from './CardImage'
import { ConfigurableCta } from 'app/_components/ContentfulPage/ConfigurableCta'
import { IContentfulConfigurableCtaEntry } from 'types/contentful/configurableCta.types'
import { useRouter } from 'next/router'

export const HorizontalCardFooterLinks = ({
  align = 'left',
  links,
  className,
}: {
  align: 'left' | 'right' | 'center'
  links: IContentfulConfigurableCtaEntry[]
  className
}) => {
  return (
    <div
      className={classnames(
        {
          'left-0': !align || align === 'left',
          'right-0': align === 'right',
          'left-1/2 -translate-x-1/2': align === 'center',
        },
        `flex absolute bottom-0 ${className}`
      )}
      onClick={(event) => event.stopPropagation()}
    >
      {links?.length &&
        links.map((link) => {
          return (
            <ConfigurableCta
              {...link}
              key={link.ctaLabel}
              className={classnames('font-semibold whitespace-nowrap', {
                'mr-10': !align || align === 'left',
                'ml-10': align === 'right',
                'mx-5': align === 'center',
              })}
            />
          )
        })}
    </div>
  )
}
export const HorizontalCardTitle = ({
  titleSize = '',
  mobileTitleSize = '',
  children,
  ...props
}) => {
  const { isMobile } = useDevice()
  const titleSizeClass = isMobile
    ? titleSizeToClass[mobileTitleSize] || titleSizeToClass[titleSize]
    : titleSizeToClass[titleSize] || titleSizeToClass.XL

  return (
    <div
      {...props}
      className={classnames(
        'font-semibold text-[clamp(16px,calc(1rem+((1vw-4.81px)*4.171)),56px)] leading-[1.2]',
        props.className ?? ''
      )}
      style={{
        fontSize: `clamp(16px, calc(1rem + ((1vw - 4.81px) * 4.171)), ${
          titleSizeClass || titleSizePx.XL
        })`,
      }}
    >
      {children}
    </div>
  )
}

export const HorizontalCardSubtitle = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'text-[clamp(16px,calc(1rem+((1vw-4.81px)*1.4599)),24px)] font-bold',
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

export const HorizontalCardDescription = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'text-[clamp(12px,1vw+8px,20px)]',
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

export const HorizontalCardText = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'text-[clamp(12px,0.6vw+5px,14px)]',
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

type TImagePosition = Omit<THorizontalPosition, 'center'> | TOverlay

interface IHorizontalCardProps extends React.HTMLProps<HTMLDivElement> {
  backgroundColor?: string
  textBlockAlign?: THorizontalPosition
  textBlockVerticalAlign?: TVerticalPosition
  image?: IImage
  imagePosition?: TImagePosition
  cardSize?: TSize
  priorityLoadImage?: boolean
  cardFooterLinks: IContentfulConfigurableCtaEntry[]
  cardFooterLinksAlign: 'left' | 'right' | 'center'
  cardLink?: string
  cardCta?: IContentfulConfigurableCtaEntry
}

const horizontalImagePosition = ['left', 'right', 'overlay']

export const HorizontalCard = ({
  backgroundColor,
  textBlockAlign = 'left',
  textBlockVerticalAlign = 'middle',
  image,
  imagePosition = 'left',
  children,
  cardSize,
  priorityLoadImage,
  cardFooterLinks,
  cardFooterLinksAlign,
  cardLink,
  cardCta = null,
  ...props
}: IHorizontalCardProps) => {
  const { isMobile } = useDevice()
  const router = useRouter()

  const hasImage = !!image?.imageUrl
  const imageRatio = 50

  const cardImageStyle = {}
  const cardTextStyle = {}
  const cardWrapperStyle = {}
  if (cardSize === 'xxs') {
    cardImageStyle['height'] = '40px'
    cardImageStyle['width'] = '40px'

    cardTextStyle['flexDirection'] = 'row'
    cardTextStyle['justifyContent'] = 'flex-start'
    cardTextStyle['width'] = '80%'
    cardTextStyle['padding'] = '6px'

    cardWrapperStyle['flexDirection'] = 'row'
  } else {
    cardImageStyle['width'] = isMobile ? '100%' : `${imageRatio || 50}%`

    cardTextStyle['flexDirection'] = 'col'
    cardTextStyle['width'] = image?.imageUrl
      ? isMobile
        ? '100%'
        : `${100 - (imageRatio || 50)}%`
      : '100%'
  }

  let expectedImagePosition = imagePosition

  if (!horizontalImagePosition.includes('' + imagePosition)) {
    expectedImagePosition = 'left'
  }

  const shouldCardBeClickable = Boolean(cardLink)

  return (
    <div
      {...props}
      className={classnames(
        'relative flex w-full h-full overflow-hidden',
        textBlockAlign === 'left' && 'justify-start text-left',
        textBlockAlign === 'center' && 'justify-center text-center',
        textBlockAlign === 'right' && 'justify-end text-right',
        props.className && props.className,
        shouldCardBeClickable && 'cursor-pointer group/card-wrapper'
      )}
      style={{ backgroundColor, ...(props.style || {}) }}
      onClick={() => (shouldCardBeClickable ? router.push(cardLink) : () => {})}
    >
      {expectedImagePosition === 'overlay' ? (
        <>
          {hasImage && (
            <CardImage
              src={image?.imageUrl}
              title={image?.imageTitle}
              backgroundColor={backgroundColor}
              priorityLoadImage={priorityLoadImage}
              className="scale-100 group-hover/card-wrapper:scale-105 origin-center transition-transform duration-300 @xl:min-h-[600px] "
            />
          )}

          {children && (
            <div
              className={classnames(
                hasImage && 'absolute',
                `@container/text-content flex flex-col gap-[clamp(20px,0.7vw+5px,18px)] p-[24px] w-[100%]`,
                textBlockAlign === 'left' &&
                  'justify-start items-start text-left px-[36px] md:px-[7%]',
                textBlockAlign === 'center' &&
                  'justify-center items-center text-center',
                textBlockAlign === 'right' &&
                  'justify-end items-end text-right px-[36px] md:px-[7%]',
                textBlockVerticalAlign === 'top' && hasImage && 'top-0',
                textBlockVerticalAlign === 'middle' &&
                  hasImage &&
                  'top-1/2 -translate-y-1/2',
                textBlockVerticalAlign === 'bottom' && hasImage && 'bottom-0'
              )}
            >
              {children}
            </div>
          )}

          {Boolean(cardFooterLinks?.length) && (
            <HorizontalCardFooterLinks
              className={classnames(`hidden lg:flex bottom-[24px] z-1`, {
                'px-[36px] md:px-[7%]':
                  textBlockAlign === 'left' || textBlockAlign === 'right',
              })}
              links={cardFooterLinks}
              align={cardFooterLinksAlign}
            />
          )}
        </>
      ) : (
        <div
          className={classnames(
            'flex w-full h-full flex-col xs:flex-row items-center',
            textBlockAlign === 'left' && 'justify-start text-left ml-[7%]',
            textBlockAlign === 'center' && 'justify-center text-center',
            textBlockAlign === 'right' && 'justify-end text-right mr-[7%]'
          )}
          style={{
            ...cardWrapperStyle,
          }}
        >
          {expectedImagePosition === 'left' && hasImage && (
            <div
              style={{ width: isMobile ? '100%' : '50%', ...cardImageStyle }}
            >
              <CardImage
                src={image?.imageUrl}
                title={image?.imageTitle}
                backgroundColor={backgroundColor}
                priorityLoadImage={priorityLoadImage}
              />
            </div>
          )}

          <div
            className={classnames(
              'flex flex-col gap-[clamp(14px,1vw+5px,18px)] py-[72px] px-[24px]',
              textBlockAlign === 'left' && 'items-start text-left',
              textBlockAlign === 'center' && 'items-center text-center',
              textBlockAlign === 'right' && 'items-end text-right',
              textBlockVerticalAlign === 'top' && 'justify-start',
              textBlockVerticalAlign === 'middle' && 'justify-center',
              textBlockVerticalAlign === 'bottom' && 'justify-end'
            )}
            style={{
              width: hasImage ? (isMobile ? '100%' : '50%') : '100%',
              ...cardTextStyle,
            }}
          >
            {children}
          </div>

          {Boolean(cardFooterLinks?.length) && (
            <HorizontalCardFooterLinks
              className={classnames(`hidden lg:flex bottom-[24px] z-1`, {
                'pl-[7%]': textBlockAlign === 'left',
                'pr-[7%]': textBlockAlign === 'right',
              })}
              links={cardFooterLinks}
              align={cardFooterLinksAlign}
            />
          )}

          {expectedImagePosition === 'right' && hasImage && (
            <div style={{ width: isMobile ? '100%' : '50%', height: '100%' }}>
              <CardImage
                src={image?.imageUrl}
                title={image?.imageTitle}
                backgroundColor={backgroundColor}
                priorityLoadImage={priorityLoadImage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
